export default {
  colors: {
    background: '#181a1b',
    text: '#e8e6e3',
    primary: '#6d846f',
    secondary: '#60763a',
    tertiary: '#7587a1',
    highlight: '#b88fb6',
  },
  fonts: {
    body:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
  },
  sizes: {
    maxWidth: '1050px',
    maxWidthCentered: '650px',
  },
  responsive: {
    small: '35em',
    medium: '50em',
    large: '70em',
  },
}
